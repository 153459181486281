import React from 'react';
import styles from './styles.module.scss';
import LogoImg from '@assets/iqos-logo2.svg';
import { useIntl } from 'gatsby-plugin-intl';

const Logo = () => {
	const intl = useIntl();

	return (
		<div className={styles.logo}>
			<LogoImg className={styles.logoSvg} />
			<p className={styles.logoText}>{intl.formatMessage({ id: 'quickStart' })}</p>
		</div>
	);
};

export default Logo;
