import React from 'react'
import styles from './styles.module.scss'
import { Grid } from '@element-softworks/daintree'
import { useIntl } from 'gatsby-plugin-intl'
import Button from '@components/Button'
import FadeIn from '@components/FadeIn'

const Welcome = ({ onComplete }) => {
	const intl = useIntl()

	return (
		<div className={styles.welcomeStep}>
			<div className={styles.gridWrapper}>
				<Grid>
					<FadeIn>
						<h1 className={styles.welcomeStepTitle}>{intl.formatMessage({ id: 'steps.step1.welcome' })}</h1>
					</FadeIn>
					<FadeIn delay={300}>
						<p className={styles.welcomeStepText}>
							{intl.formatMessage({ id: 'steps.step1.startJourney' })}
						</p>
					</FadeIn>
					<FadeIn delay={600}>
						<Button onClick={onComplete}>{intl.formatMessage({ id: 'steps.step1.button' })}</Button>
					</FadeIn>
				</Grid>
			</div>
		</div>
	)
}

export default Welcome
