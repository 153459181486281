import React, { useState } from 'react';
import styles from './styles.module.scss';
import Logo from '@assets/iqos-logo3.svg';
import Info from '@assets/icon-info2.svg';
import languages from '@data/languages';
import { navigate } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import NavigationInfoModal from './NavigationInfoModal';

import FlagEN from '@assets/flag-en.svg';
import FlagES from '@assets/flag-es.svg';
import FlagFR from '@assets/flag-fr.svg';
// import FlagDE from '@assets/flag-de.svg';
import queryString from 'query-string';
// import FlagRU from '@assets/flag-ru.svg'

const Navigation = () => {
	const intl = useIntl();
	const stepFromQuery = queryString.parse(location.search, { parseNumbers: true });
	const step = stepFromQuery?.step || 0;
	const convertFlagCode = code => {
		if (!code) return 'gb';
		if (code === 'en') return 'gb';
		if (code === 'ko') return 'kr';
		return code;
	};
	const Flag = ({ code }) => {
		if (code === 'en') return <FlagEN />;
		if (code === 'es') return <FlagES />;
		if (code === 'fr') return <FlagFR />;
		// if (code === 'de') return <FlagDe />
		// fallback support if SVG flag isn't present
		return <img alt={code} src={`https://www.countryflags.io/${convertFlagCode(code)}/flat/48.png`} />;
	};

	const [langSelectOpen, setLangSelectOpen] = useState(false);
	const [infoModalOpen, setInfoModalOpen] = useState(false);
	return (
		<div className={styles.navigation}>
			<div className={styles.navigationLogo}>
				<Logo />
			</div>
			<p className={styles.navigationText}>{intl.formatMessage({ id: 'quickStart' })}</p>

			<div className={styles.navigationInfo} onClick={() => setInfoModalOpen(!infoModalOpen)}>
				<Info />
			</div>
			{infoModalOpen && <NavigationInfoModal onClose={() => setInfoModalOpen(false)} />}
			<div className={styles.navigationLanguage}>
				<div className={styles.navigationLanguageSelected} onClick={() => setLangSelectOpen(!langSelectOpen)}>
					<Flag code={intl?.locale || 'en'} />
				</div>
				{langSelectOpen && (
					<div className={styles.navigationLanguageDropdown}>
						{languages.map(language => {
							// don't display the current language flat
							if ((intl?.locale || 'en') === language?.value) return null;

							return (
								<div
									key={language?.value}
									onClick={() =>
										navigate(`/${language?.value}/?query=videos&step=${step}&ageOverride=true`, {
											replace: true,
										})
									}
								>
									<Flag code={language?.value} />
								</div>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};

export default Navigation;
