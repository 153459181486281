import React from 'react';
import styles from './styles.module.scss';
import { useIntl } from 'gatsby-plugin-intl';
import IconArrow from '@assets/icon-left-arrow.svg';
import Button from '@components/Button';
import FadeIn from '@components/FadeIn';
import Cross from "@assets/icon-cross.svg";

const NavigationInfoModal = ({ onClose }) => {
	const intl = useIntl();
	return (
		<div className={styles.infoModal}>
			<div className={styles.infoModalText}>
				<div onClick={onClose} className={styles.infoModalClose}>
					<Cross />
				</div>
				<h2>{intl.formatMessage({ id: 'infoModal.header' })}</h2>
				<hr />
				<p>{intl.formatMessage({ id: 'infoModal.text1' })}</p>
				<p>{intl.formatMessage({ id: 'infoModal.text2' })}</p>
				<hr />
				<ul>
					<li>{intl.formatMessage({ id: 'infoModal.list.listText1' })}</li>
					<li>{intl.formatMessage({ id: 'infoModal.list.listText2' })}</li>
					<li>{intl.formatMessage({ id: 'infoModal.list.listText3' })}</li>
					<li>{intl.formatMessage({ id: 'infoModal.list.listText4' })}</li>
				</ul>
				<hr />
				<div className="scroll-to-reveal scrolled">
					<h3>{intl.formatMessage({ id: 'infoModal.tobaccoEffects.header' })}</h3>
					<ul>
						<li>{intl.formatMessage({ id: 'infoModal.tobaccoEffects.list.listText1' })}</li>
						<li>{intl.formatMessage({ id: 'infoModal.tobaccoEffects.list.listText2' })}</li>
						<li>{intl.formatMessage({ id: 'infoModal.tobaccoEffects.list.listText3' })}</li>
						<li>{intl.formatMessage({ id: 'infoModal.tobaccoEffects.list.listText4' })}</li>
					</ul>
					<hr />
					<h3>{intl.formatMessage({ id: 'infoModal.nicotine.header' })}</h3>
					<ul>
						<li>{intl.formatMessage({ id: 'infoModal.nicotine.list.listText1' })}</li>
						<li>{intl.formatMessage({ id: 'infoModal.nicotine.list.listText2' })}</li>
						<li>{intl.formatMessage({ id: 'infoModal.nicotine.list.listText3' })}</li>
						<li>{intl.formatMessage({ id: 'infoModal.nicotine.list.listText4' })}</li>
					</ul>
					<hr />
					<h3>{intl.formatMessage({ id: 'infoModal.children.header' })}</h3>
					<ul>
						<li>{intl.formatMessage({ id: 'infoModal.children.list.listText1' })}</li>
						<li>{intl.formatMessage({ id: 'infoModal.children.list.listText2' })}</li>
					</ul>
				</div>
				<Button onClick={onClose}>{intl.formatMessage({ id: 'close' })}</Button>
			</div>
		</div>
	);
};

export default NavigationInfoModal;
