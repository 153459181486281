import React from 'react';
import styles from './styles.module.scss';
import List from '@components/List';
import Button from '@components/Button';
import IconPhone3 from '@assets/icon-phone3.svg';
import IconRegister2 from '@assets/icon-register2.svg';
import Cross from '@assets/icon-cross.svg';
import { useIntl } from 'gatsby-plugin-intl';
import supportedCountries from '@data/supportedCountries.json';
import { useDispatch, useSelector } from 'react-redux';
import FadeIn from '@components/FadeIn';
import { setEvent } from "@actions/event";

const RegisterModal = ({ onClose, onPhoneClick }) => {
	const intl = useIntl();
	const user = useSelector(state => state.user);
	const country = user?.country;
	const dispatch = useDispatch();
	const event = useSelector(state => state.event);

	const handOff = (phone = false) => {
		if (typeof window !== 'undefined') {
			const end = supportedCountries.find(supportedCountry => supportedCountry.code === country.toUpperCase());
			window.location.href = phone ? `tel:${end.phone}` : end.url;
		}
		dispatch(setEvent({...event, handOffOnline: new Date() }))

	};

	return (
		<div className={styles.registerModal}>
			<FadeIn>
				<div className={styles.registerModalClose} onClick={onClose}>
					<Cross />
				</div>
			</FadeIn>
			<div className={styles.registerModalWrapper}>
				<List>
					<List.ListItem fadeIn={0}>
						{intl.formatMessage({ id: 'steps.videos.registerBenefits.benefit1' })}
					</List.ListItem>
					<List.ListItem fadeIn={300}>
						{intl.formatMessage({ id: 'steps.videos.registerBenefits.benefit2' })}
					</List.ListItem>
					<List.ListItem fadeIn={600}>
						{intl.formatMessage({ id: 'steps.videos.registerBenefits.benefit3' })}
					</List.ListItem>
				</List>
				<FadeIn delay={900}>
					<Button
						outline
						Icon={IconPhone3}
						onClick={() => {
							onPhoneClick();
							onClose();
						}}
					>
						{intl.formatMessage({ id: 'steps.videos.callToRegister' })}
					</Button>
				</FadeIn>
				<FadeIn delay={1200}>
					<Button Icon={IconRegister2} onClick={() => handOff()}>
						{intl.formatMessage({ id: 'steps.videos.registerOnline' })}
					</Button>
				</FadeIn>
			</div>
		</div>
	);
};

export default RegisterModal;
