import React from 'react';
import styles from './styles.module.scss';
import { Grid } from '@element-softworks/daintree';
import { useIntl } from 'gatsby-plugin-intl';
import Button from '@components/Button';
import Logo from '@components/Logo';
import FadeIn from '@components/FadeIn';

const CountryNotAvailable = ({ onComplete }) => {
	const intl = useIntl();

	return (
		<div className={styles.countryNotAvailable}>
			<Grid>
				<FadeIn>
					<h2>{intl.formatMessage({ id: 'steps.step3Unavailable.title' })}</h2>
				</FadeIn>

				<FadeIn delay={300}>
					<p>{intl.formatMessage({ id: 'steps.step3Unavailable.text1' })}</p>
				</FadeIn>

				<FadeIn delay={600}>
					<p>{intl.formatMessage({ id: 'steps.step3Unavailable.text2' })}</p>
				</FadeIn>

				<FadeIn delay={900}>
					<a href={"https://iqos.com"}>
						<Button>{intl.formatMessage({ id: 'continue' })}</Button>
					</a>
				</FadeIn>
			</Grid>
		</div>
	);
};

export default CountryNotAvailable;
