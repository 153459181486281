import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Tick from '@assets/icon-tick.svg';

const List = ({ current, children }) => {
	const props = [];
	let index = 0;

	React.Children.forEach(children, child => {
		if (child && child.type && (child.type.displayName || child.type.name) === 'Step') {
			props.push({
				active: child.props.name === current,
				number: index + 1,
			});
			index++;
		}
	});

	index = -1;

	return (
		<ul className={styles.list}>
			{React.Children.map(children, child => {
				if (child && child.type && (child.type.displayName || child.type.name) === 'Step') {
					index++;

					return (child && child.type && (child.type.displayName || child.type.name)) === 'Step'
						? React.cloneElement(child, props[index])
						: child;
				}

				return child;
			})}
		</ul>
	);
};

const ListItem = ({ children, active, fadeIn = false }) => {
	const [showLoading, setShowLoading] = useState(false);
	useEffect(() => {
		let timer1 = setTimeout(() => setShowLoading(true), fadeIn);
		return () => clearTimeout(timer1);
	}, []);

	return (
		<li
			// onClick={() => console.log('hello')}
			aria-hidden="true"
			className={`${styles.listItem} ${showLoading ? styles.listItemVisible : ''}`}
		>
			<Tick />
			<p>{children}</p>
		</li>
	);
};

ListItem.displayName = 'ListItem';
List.ListItem = ListItem;
export default List;
