import React, { useEffect } from 'react';
import SEO from '@components/SEO';
import '@styles/style.scss';
import styles from './styles.module.scss';
import AgeVerification from '@views/AgeVerification';


const Layout = ({ children, className = '' }) => {
	useEffect(() => {
		if (typeof window !== 'undefined') {
			function handleResize() {
				let vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty('--vh', `${vh}px`);
				// console.log('resized');
			}

			window.addEventListener('resize', handleResize);

			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}
	}, []);
	return (
		<div className={`${styles.app} ${className}`}>
			<SEO />
			{/*<Navigation />*/}
			<main>
				<AgeVerification/>
				<div className={styles.content}>{children}</div>
				{/*<Footer />*/}
			</main>
		</div>
	);
};

export default Layout;
