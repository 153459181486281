import React from 'react';
import styles from './styles.module.scss';
import { navigate } from '@reach/router';
import queryString from 'query-string';

const Steps = ({ current, children, hideNumbers }) => {
	const props = [];
	let index = 0;

	React.Children.forEach(children, child => {
		if (child && child.type && (child.type.displayName || child.type.name) === 'Step') {
			props.push({
				active: child.props.name === current,
				number: index + 1,
				hideNumbers,
				complete: !!current
					? typeof child.props.complete !== 'undefined'
						? child.props.complete
						: child.props.name !== current && !props.some(stepProps => stepProps.active)
					: false,
			});

			index++;
		}
	});

	index = -1;

	return (
		<ul className={styles.steps}>
			{React.Children.map(children, child => {
				if (child && child.type && (child.type.displayName || child.type.name) === 'Step') {
					index++;

					return (child && child.type && (child.type.displayName || child.type.name)) === 'Step'
						? React.cloneElement(child, props[index])
						: child;
				}

				return child;
			})}
		</ul>
	);
};

const Step = ({ title, name, active, number, complete, hideNumbers }) => {
	const setQuery = newQuery => navigate(`?${queryString.stringify({ query: newQuery })}`);
	return (
		<li
			// onClick={e => setQuery(name)}
			aria-hidden="true"
			className={`${styles.stepsStep} ${active ? styles.stepsStepActive : ''} ${
				complete ? styles.stepsStepComplete : ''
			}`}
		>
			<span>
				<span className={styles.stepsBadge}>
					{complete ? <div>Y</div> : hideNumbers ? '' : <span>{number}</span>}
				</span>
				{title}
			</span>
		</li>
	);
};

Step.displayName = 'Step';
Steps.Step = Step;
export default Steps;
