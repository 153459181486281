import React from 'react';
import styles from './styles.module.scss';
import { useIntl } from 'gatsby-plugin-intl';

const Select = ({ data, label, value, onChange, bordered, placeholder = false }) => {
	const intl = useIntl();

	return (
		<div className={`${styles.select} ${bordered ? styles.selectBordered : ''}`}>
			{label && <label>{label}</label>}
			<select value={value ? value : -1} onChange={onChange}>
				<option value={-1} disabled>
					{placeholder ? placeholder : intl.formatMessage({ id: 'selectDefaultOptionText' })}
				</option>
				{data.map(({ value, label }) => (
					<option key={value} value={value}>
						{label}
					</option>
				))}
			</select>
		</div>
	);
};

export default Select;
