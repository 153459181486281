import styles from './styles.module.scss';
import React from 'react';

const Button = ({ children, onClick, disabled, outline, small, Icon }) => {
	return (
		<button
			className={`${styles.button} ${!!outline ? styles.outline : ''} ${!!small ? styles.small : ''}`}
			onClick={onClick}
			disabled={disabled}
		>
			{!!Icon ? (
				<span className={styles.icon}>
					<Icon />
				</span>
			) : null}
			{children}
		</button>
	);
};

export default Button;
