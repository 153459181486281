import React, { Fragment, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Footer from '@components/Footer';
import Navigation from '@components/Navigation';
import Button from '@components/Button';
import VideoStories from '@components/VideoStories';
import TourModal from './TourModal';
import CallModal from './CallModal';
import RegisterModal from './RegisterModal';
import videosList from '@data/videos';
import { useIntl } from 'gatsby-plugin-intl';
import { useSwipeable } from 'react-swipeable';
import { useDispatch, useSelector } from 'react-redux';

import IconPhone3 from '@assets/icon-phone3.svg';
import IconRegister2 from '@assets/icon-register2.svg';
import IconSpeaker from '@assets/icon-speaker.svg';
import IconSpeakerMuted from '@assets/icon-speaker-muted.svg';
import IconCaptions from '@assets/icon-captions.svg';

import { navigate } from 'gatsby';
import queryString from 'query-string';
import { useLocation } from '@reach/router';
import supportedCountries from "@data/supportedCountries.json";
import { setEvent } from "@actions/event";

const Videos = () => {
	const [displayModal, setDisplayModal] = useState(false);
	const intl = useIntl();
	const language = intl?.locale || 'en';
	const [paused, setPaused] = useState(true);
	const [muted, setMuted] = useState(true);
	const [captions, setCaptions] = useState(false);
	const [loading, setLoading] = useState(true);
	const [tourMode, setTourMode] = useState(true);
	const [callModal, setCallModal] = useState(false);
	const [watchedCounter, setWatchedCounter] = useState(0);
	const [displayNamePreview, setDisplayNamePreview] = useState(false);
	const [displayNamePreviewTimeout, setDisplayNamePreviewTimeout] = useState(null);

	const location = useLocation();
	const stepFromQuery = queryString.parse(location.search, { parseNumbers: true });
	const step = stepFromQuery?.step || 0;

	const user = useSelector(state => state.user);
	const firstDevice = user?.firstDevice;
	const country = user?.country;

	const dispatch = useDispatch();
	const event = useSelector(state => state.event);

	const url = process.env.NODE_ENV === 'production' ? 'https://tipsandtricks.260438f79400.com' : 'http://localhost:8000';

	const handOff = (phone = false) => {
		console.log("handOff")
		const data = phone ? {handOffPhone: new Date()} : {handOffOnline: new Date()};
		dispatch(setEvent({...event, ...data }))
		if (typeof window !== 'undefined') {
			const end = supportedCountries.find(supportedCountry => supportedCountry.code === country.toUpperCase());
			window.location.href = phone ? `tel:${end.phone}` : end.url;
		}
	};

	const storiesData = videosList
		?.find(list => list.firstDevice === !!firstDevice)
		?.videosList?.map(video => ({
			fileName: video?.name,
			url: `${url}/videos/${video?.name}.mp4`,
			subtitles: [
				{
					kind: 'subtitles',
					src: `${url}/videos/subtitles/${video?.name}.${language}.vtt`,
					srcLang: language,
					label: video?.name,
					default: true,
				},
				{
					kind: 'subtitles',
					src: `${url}/videos/subtitles/${video?.name}.${language}.srt`,
					srcLang: language,
					label: video?.name,
				},
			],
			description: video?.description,
			coverScreen: video?.coverScreen,
		}));

	const openRegisterModal = open => {
		console.log('openRegisterModal');
		setDisplayModal(open);
		setPaused(open);
	};

	const toggleCallModal = () => {
		setCallModal(!callModal);
		setPaused(!callModal);
		dispatch(setEvent({...event, handOffPhone: new Date() }))
	};

	const closeTourModal = () => {
		console.log('closeTourModal');
		setTourMode(false);
		setLoading(false);
		setPaused(false);
		setTimeout(() => openRegisterModal(true), 30000);
	};

	useEffect(() => {
		// if they have watched two or more videos then open modal and reset counter
		// check that they are in a registered country as well
		if (watchedCounter >= 2 && !!user?.country) {
			openRegisterModal(true);
			ensurePause(true, 800);
			setWatchedCounter(0);
		}
	}, [watchedCounter]);

	const previousStory = () => {
		console.log('previousStory', step);
		// prevent them from going to -1 videos index
		if (step > 0) navigate(`/${language}/?query=videos&step=${step - 1}`);
	};
	const nextStory = () => {
		// on swipe right or end of current story run next story
		console.log('nextStory', step);
		if (step + 1 >= storiesData.length) {
			// if they've watched all the videos
			if (!!user?.country) {
				openRegisterModal(true);
				ensurePause(true);
			}
		} else navigate(`/${language}/?query=videos&step=${step + 1}`);
		dispatch(setEvent({...event, content: storiesData[step]}))
	};

	const ensurePause = (boolean, delay = 50) => {
		setPaused(boolean);
		// setPaused can be buggy sometimes, timeout added for extra confidence
		// setTimeout(() => setPaused(boolean), delay);
	};

	const handlers = useSwipeable({
		onSwiped: eventData => {
			if (eventData?.dir === 'Left') nextStory();
			if (eventData?.dir === 'Right') previousStory();
			if (eventData?.dir === 'Up') user?.country && openRegisterModal(true);
			if (eventData?.dir === 'Left' || eventData?.dir === 'Right') setPaused(false);
		},
		onTap: () => setPaused(!paused),
		// trackMouse: true,
	});
	const handlersLeft = useSwipeable({
		onTap: () => previousStory(),
		onSwiped: eventData => {
			if (eventData?.dir === 'Left') nextStory();
			if (eventData?.dir === 'Right') previousStory();
			if (eventData?.dir === 'Up') user?.country && openRegisterModal(true);
			if (eventData?.dir === 'Left' || eventData?.dir === 'Right') setPaused(false);
		},
	});
	const handlersRight = useSwipeable({
		onTap: () => nextStory(),
		onSwiped: eventData => {
			if (eventData?.dir === 'Left') nextStory();
			if (eventData?.dir === 'Right') previousStory();
			if (eventData?.dir === 'Up') user?.country && openRegisterModal(true);
			if (eventData?.dir === 'Left' || eventData?.dir === 'Right') setPaused(false);
		},
	});

	useEffect(() => {
		clearTimeout(displayNamePreviewTimeout);
		setDisplayNamePreview(true);
		setDisplayNamePreviewTimeout(
			setTimeout(() => {
				setDisplayNamePreview(false);
			}, 3000),
		);
	}, [step]);

	return (
		<Fragment>
			<Navigation />
			<div className={styles.swipeAreaWrapper}>
				<div className={styles.swipeArea} {...handlers} />
				<div className={`${styles.swipeArea} ${styles.swipeAreaLeft}`} {...handlersLeft} />
				<div className={`${styles.swipeArea} ${styles.swipeAreaRight}`} {...handlersRight} />
				<div className={`${styles.swipeControls} ${!paused ? styles.swipeControlsDisplay : ''} ${displayNamePreview ? styles.swipeControlsDisplayMove : ''}`}>
					<div
						className={`${styles.swipeControlsMute} ${muted ? styles.swipeControlsFaded : ''}`}
						onClick={() => setMuted(!muted)}
					>
						{!muted ? <IconSpeaker /> : <IconSpeakerMuted />}
					</div>
					<div
						className={`${styles.swipeControlsCaptions} ${!captions ? styles.swipeControlsFaded : ''}`}
						onClick={() => setCaptions(!captions)}
					>
						<IconCaptions />
					</div>
				</div>
			</div>
			<div className={styles.wrapper}>
				{tourMode ? <TourModal onClose={closeTourModal} /> : ''}
				{callModal ? <CallModal onClose={toggleCallModal} /> : ''}
				{displayModal && user?.country ? <RegisterModal onClose={() => openRegisterModal(false)} onPhoneClick={toggleCallModal}/> : ''}
				<VideoStories
					stories={storiesData}
					currentIndex={step}
					paused={paused}
					muted={muted}
					displayNamePreview={displayNamePreview}
					hidePauseInfo={tourMode || displayModal}
					captions={captions}
					onStoryEnd={() => {
						console.log('onStoryEnd', loading);
						setWatchedCounter(watchedCounter + 1);
						// sometimes buggy where the story starts the next video on page load, this prevents it
						!loading && nextStory();
					}}
					smallerPlayer={user?.country}
				/>
				{!!user?.country && (
					<div className={styles.buttonWrapper}>
						<div>
							<Button outline small Icon={IconPhone3} onClick={toggleCallModal}>
								{intl.formatMessage({ id: 'steps.videos.callToRegister' })}
							</Button>
						</div>
						<div>
							<Button small Icon={IconRegister2} onClick={() => handOff(false)}>
								{intl.formatMessage({ id: 'steps.videos.registerOnline' })}
							</Button>
						</div>
					</div>
				)}
			</div>
			<Footer />
		</Fragment>
	);
};

export default Videos;
