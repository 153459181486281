import styles from './styles.module.scss';
import React, { useEffect, useState } from 'react';
import IconPlay from '@assets/icon-play.svg';
import ReactPlayer from 'react-player/lazy';
import { useForceUpdate, usePrevious } from '@helpers';

const VideoStories = ({
	stories,
	currentIndex = 0,
	paused,
	onStoryEnd,
	hidePauseInfo,
	smallerPlayer = false,
	muted,
	captions,
	displayNamePreview
}) => {
	const [progress, setProgress] = useState({ played: 0, loaded: 0 });
	const [reRendering, setReRendering] = useState(false);
	const prevIndex = usePrevious(currentIndex);
	const prevCaptions = usePrevious(captions);

	useEffect(() => {
		let timeout;
		// if changed video, set progress back to 0
		if (prevIndex !== currentIndex) setProgress({ played: 0, loaded: 0 });
		// if subtitles turned on and either video never been paused or was paused but now pressed play
		// then force re-render whole component
		if (prevIndex !== currentIndex || (!prevCaptions && captions)) {
			setReRendering(true);

			setTimeout(() => {
				setReRendering(false);
			}, 0);
		}

		return () => clearTimeout(timeout);
	}, [currentIndex, captions]);

	const handleEnded = () => onStoryEnd();
	const story = stories[currentIndex];

	return (
		<div className={styles.stories}>
			<div
				className={`${styles.storiesNavigation} ${paused ? styles.storiesNavigationPaused : ''} ${
					displayNamePreview ? styles.storiesNavigationPaused : ''
				}`}
			>
				<ul>
					{stories?.map((story, index) => {
						return (
							<li key={story.url}>
								<div
									className={`${index === currentIndex ? styles.storiesNavigationCurrent : ''} 
									${index < currentIndex ? styles.storiesNavigationPrevious : ''}`}
								>
									<div
										style={
											index === currentIndex
												? { width: `${(progress.played / progress.loaded) * 100}%` }
												: {}
										}
									/>
								</div>
							</li>
						);
					})}
				</ul>
				{!hidePauseInfo && (
					<div className={styles.storiesNavigationDescription}>
						{stories?.find((story, index) => index === currentIndex)?.description}
					</div>
				)}
			</div>

			{!hidePauseInfo && paused && (
				<div className={styles.storiesPaused}>
					<IconPlay />
				</div>
			)}

			{reRendering ? null : (
				<ReactPlayer
					width="100%"
					height={`calc((var(--vh, 1vh) * 100) - ${smallerPlayer ? '186px' : '88px'})`}
					className={`${styles.storiesVideo} ${story.coverScreen ? styles.storiesVideoCover : ''}`}
					url={[story?.url]}
					playing={!paused}
					muted={muted}
					playsinline={true}
					onProgress={({ playedSeconds, loadedSeconds }) =>
						setProgress({ played: playedSeconds, loaded: loadedSeconds })
					}
					playIcon={<IconPlay />}
					config={{
						file: {
							attributes: {
								crossOrigin: 'true',
								// currenttime: progress.played,
							},
							tracks: captions ? story?.subtitles : [],
						},
					}}
					onEnded={handleEnded}
				/>
			)}
		</div>
	);
};

export default VideoStories;
