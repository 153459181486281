import React from 'react';
import styles from './styles.module.scss';
import { Grid } from '@element-softworks/daintree';
import { useIntl } from 'gatsby-plugin-intl';
import Button from '@components/Button';
import List from '@components/List';
import FadeIn from '@components/FadeIn';

const Benefits = ({ onComplete }) => {
	const intl = useIntl();

	return (
		<div className={styles.benefits}>
			<Grid>
				<FadeIn>
					<h2>{intl.formatMessage({ id: 'steps.step3.benefitsOfRegistering' })} </h2>
				</FadeIn>
				<List>
					<List.ListItem fadeIn={300}>{intl.formatMessage({ id: 'steps.step3.benefit1' })}</List.ListItem>
					<List.ListItem fadeIn={600}>{intl.formatMessage({ id: 'steps.step3.benefit2' })}</List.ListItem>
					<List.ListItem fadeIn={900}>{intl.formatMessage({ id: 'steps.step3.benefit3' })}</List.ListItem>
				</List>
				<FadeIn delay={1200}>
					<Button onClick={onComplete}>{intl.formatMessage({ id: 'continue' })}</Button>
				</FadeIn>
			</Grid>
		</div>
	);
};

export default Benefits;
