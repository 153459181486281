import React from 'react';
import styles from './styles.module.scss';
import { useIntl } from 'gatsby-plugin-intl';

const Footer = () => {
	const intl = useIntl();

	return (
		<div className={styles.footer}>
			{/*<p>{intl.formatMessage({ id: 'footer' })}</p>*/}
			<p>
				Dieses Tabakerzeugnis kann Ihre Gesundheit schädigen und macht abhängig.<br/>
				Ce produit du tabac peut nuire à votre santé et crée une dépendance.<br/>
				Questo prodotto del tabacco può nuocere alla tua salute e provoca dipendenza.
			</p>
		</div>
	);
};

export default Footer;
