import React from 'react';
import styles from './styles.module.scss';
import { navigate, useLocation } from '@reach/router';
import queryString from 'query-string';
import supportedCountries from '@data/supportedCountries';
import { useDispatch, useSelector } from 'react-redux';
import { addUser } from '@actions/user';
import Layout from '@components/Layout';
import Footer from '@components/Footer';
import Steps from '@components/Steps';
import Welcome from '@views/Steps/Welcome';
import SelectCountry from '@views/Steps/SelectCountry';
import Benefits from '@views/Steps/Benefits';
import CountryNotAvailable from '@views/Steps/CountryNotAvailable';
import Videos from '@views/Steps/Videos';
import Logo from '@components/Logo';
import FadeIn from '@components/FadeIn';
import { setEvent } from "@actions/event";

const Home = () => {
	const location = useLocation();
	const params = queryString.parse(location.search);
	let step = params?.query || 'welcome';

	const dispatch = useDispatch();
	const event = useSelector(state => state.event);

	const setQuery = newQuery => navigate(`${location.pathname}?${queryString.stringify({ query: newQuery })}`);

	const handleNextStep = ({ country, firstDevice }) => {
		console.log('handleNextStep');
		if (step === 'welcome') setQuery('selectCountry');
		if (step === 'selectCountry') {
			const countryExists = supportedCountries.find(selectedCountry => selectedCountry.code === country);
			dispatch(addUser({ firstDevice, country: countryExists ? country : false }));
			console.log({ ...event, countrySelected: countryExists ? country : undefined, step })
			dispatch(setEvent({ ...event, countrySelected: countryExists ? country : undefined, step }));
			setQuery(countryExists ? 'videos' : 'countryNotAvailable');
		}
		// if (step === 'benefits' || step === 'countryNotAvailable') setQuery('videos');
	};

	if (step === 'videos')
		return (
			<Layout>
				<Videos />
			</Layout>
		);

	return (
		<Layout>
			<div className={`${styles.wrapper} ${step === 'welcome' ? styles.bgWelcome : ''}`}>
				<div className={styles.wrapperOuter}>
					<div className={styles.wrapperInner}>
						<div className={styles.wrapperLogo}>
							<FadeIn>
								<Logo />
							</FadeIn>
						</div>
						{step === 'welcome' && <Welcome onComplete={handleNextStep} />}
						{step === 'selectCountry' && <SelectCountry onComplete={handleNextStep} />}
						{step === 'benefits' && <Benefits onComplete={handleNextStep} />}
						{step === 'countryNotAvailable' && <CountryNotAvailable onComplete={handleNextStep} />}
					</div>
				</div>
			</div>

			<div className={styles.stepsWrapper}>
				<FadeIn>
					<Steps current={step === 'countryNotAvailable' ? 'benefits' : step} hideNumbers>
						<Steps.Step name={'welcome'} complete={false} />
						<Steps.Step name={'selectCountry'} complete={false} />
						{/*<Steps.Step name={'benefits'} complete={false} />*/}
					</Steps>
				</FadeIn>
			</div>

			<Footer />
		</Layout>
	);
};

export default Home;
