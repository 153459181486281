import React, { useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { readCookie, bakeCookie } from '@helpers/storage';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik'; // for everything
import { endOfMonth, isAfter, subYears } from 'date-fns';
import { Grid, Row, Col } from '@element-softworks/daintree';
import styles from './styles.module.scss';
import Logo from '@assets/iqos-logo2.svg';
import Select from '@components/Select';
import { navigate } from 'gatsby';
import languages from '@data/languages';
import queryString from 'query-string';
import { useLocation } from '@reach/router';
import FadeIn from '@components/FadeIn';
import axios from 'axios'
import countries from '@data/countries-v1.1';
import { useDispatch, useSelector } from "react-redux";
import { setEvent } from "@actions/event";
import { recordEvent } from "@helpers/analytics";

const years = [];
for (let y = 2020; y > 1920; y--) {
	years.push({ value: y, label: y.toString() });
}
const months = [
	{ value: 1, label: 'January' },
	{ value: 2, label: 'February' },
	{ value: 3, label: 'March' },
	{ value: 4, label: 'April' },
	{ value: 5, label: 'May' },
	{ value: 6, label: 'June' },
	{ value: 7, label: 'July' },
	{ value: 8, label: 'August' },
	{ value: 9, label: 'September' },
	{ value: 10, label: 'October' },
	{ value: 11, label: 'November' },
	{ value: 12, label: 'December' },
];

const AgeVerification = ({ onComplete }) => {
	const [year, setYear] = useState(-1);
	const [month, setMonth] = useState(-1);
	const [complete, setComplete] = useState(false);
	const timer = 200;

	const dispatch = useDispatch();
	const event = useSelector(state => state.event);

	const intl = useIntl();
	const language = intl?.locale || 'en';

	const location = useLocation();
	const params = queryString.parse(location.search);

	const getGeoInfo = async () => {
		try {
			// if not in one of the white listed countries then redirect them to main site
			const { data } = await axios.get('https://ipapi.co/json/');
			if (!countries.find(({code}) => code === data?.country_code)) {
				if (typeof window !== 'undefined') {
					if (!params?.redirectOverride) {
						dispatch(setEvent({...event, redirectFromCountry: data?.country_code}))
						window.location.replace("https://iqos.com")
					}
				}
			} else {
				dispatch(setEvent({...params }))
			}
		} catch (error) {
			console.log({ error });
		}
	};
	useEffect(() => {
		const { year, month } = readCookie('age-verification') || {};
		setMonth(month);
		setYear(year);
		if (params.ageOverride) setComplete(true);
	}, []);
	useEffect(() => {
		const { year, month } = readCookie('age-verification') || {};
		if (!year && !month) {
			if (!event) getGeoInfo()
		}
	}, [event]);

	const handleSubmit = ({ year, month }) => {
		bakeCookie('age-verification', { year, month });
		setComplete(true);
		dispatch(setEvent({...event, ageVerified: true }))
	};

	let schema = yup
		.object()
		.shape({
			year: yup.number().required('Year not given.'),
			month: yup.number().required('Month not given.'),
		})
		.test('over18', 'Not old enough', function ({ year, month }) {
			const dob = endOfMonth(new Date(year, month, 1));
			const oldEnough = isAfter(subYears(new Date(), 18), dob);

			if (oldEnough) return true;
			return this.createError({
				path: 'over18',
				message: intl.formatMessage({ id: 'ageVerification.error' }),
			});
		});

	if (complete) return null;
	return (
		<div className={styles.verify}>
			<div className={styles.verifyWrapper}>
				<Grid>
					<FadeIn>
						<Logo className={styles.verifyLogo}/>
					</FadeIn>

					<FadeIn delay={timer}>
						<p>{intl.formatMessage({ id: 'ageVerification.intro' })}</p>
					</FadeIn>
					<FadeIn delay={timer * 2}>
						<p>{intl.formatMessage({ id: 'ageVerification.disclaimer' })}</p>
					</FadeIn>

					<FadeIn delay={timer * 3}>
						<Select
							data={languages?.sort((a, b) => a.label.localeCompare(b.label))}
							value={language}
							label={intl.formatMessage({ id: 'ageVerification.language' })}
							onChange={value => navigate(`/${value?.target?.value}/`, { replace: true })}
						/>
					</FadeIn>
					<FadeIn delay={timer * 4}>
						<p className={styles.verifyAgeCheck}>{intl.formatMessage({ id: 'ageVerification.check' })}</p>
					</FadeIn>
					<Formik
						initialValues={{ month: month || '', year: year || '' }}
						validationSchema={schema}
						onSubmit={handleSubmit}
						enableReinitialize
					>
						{({ isSubmitting, errors, touched }) => (
							<Form className={styles.verifyForm}>
								{errors?.over18 && <p className={styles.verifyFormError}>{errors?.over18}</p>}
								<Row>
									<Col xs={6}>
										<FadeIn delay={timer * 5}>
											<Field
												as="input"
												placeholder="Month"
												name="month"
												type="number"
												min={1}
												max={12}
												className={styles.verifyFormField}
											/>
											<Select
												bordered
												data={months}
												value={month}
												onChange={value => setMonth(value?.target?.value)}
												placeholder={'Month'}
											/>
										</FadeIn>
									</Col>
									<Col xs={6}>
										<FadeIn delay={timer * 6}>
											<Field
												as="input"
												placeholder="Year"
												name="year"
												type="number"
												min={1920}
												max={2020}
												className={styles.verifyFormField}
											/>
											<Select
												data={years}
												value={year}
												onChange={value => setYear(value?.target?.value)}
												bordered
												placeholder={'Year'}
											/>
										</FadeIn>
									</Col>
								</Row>

								<FadeIn delay={timer * 7}>
									<button
										type="submit"
										disabled={!(year > 0) || !(month > 0) || isSubmitting || errors?.over18}
										className={styles.verifyFormButton}
									>
										{intl.formatMessage({ id: 'ageVerification.enterSite' })}
									</button>
								</FadeIn>
							</Form>
						)}
					</Formik>
				</Grid>
			</div>
		</div>
	);
};

export default AgeVerification;
