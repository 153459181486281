import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Grid, Row, Col } from '@element-softworks/daintree';
import { useIntl } from 'gatsby-plugin-intl';
import Button from '@components/Button';
import Select from '@components/Select';
import countries from '@data/countries-v1.1';
import FadeIn from '@components/FadeIn';

const SelectCountry = ({ onComplete }) => {
	const intl = useIntl();
	const [country, setCountry] = useState('');
	const [firstDevice, setFirstDevice] = useState(true);

	return (
		<div className={styles.selectCountry}>
			<Grid>
				<FadeIn>
					<Select
						data={countries
							.map(country => ({ label: country.name, value: country.code }))}
							// .sort((a, b) => a.label.localeCompare(b.label))}
						value={country}
						label={intl.formatMessage({ id: 'steps.step2.countryOfResidence' })}
						onChange={value => setCountry(value?.target?.value)}
					/>
				</FadeIn>
				<FadeIn delay={300}>
					<p className={styles.selectCountryText}>{intl.formatMessage({ id: 'steps.step2.firstDevice' })}</p>
				</FadeIn>

				<Row>
					<Col xs={6}>
						<FadeIn delay={600}>
							<div
								role="button"
								tabIndex={0}
								onClick={() => setFirstDevice(true)}
								onKeyDown={() => setFirstDevice(true)}
								className={`${styles.selectCountryButton} ${firstDevice ? styles.active : ''}`}
							>
								{intl.formatMessage({ id: 'yes' })}
							</div>
						</FadeIn>
					</Col>
					<Col xs={6}>
						<FadeIn delay={900}>
							<div
								role="button"
								tabIndex={0}
								onClick={() => setFirstDevice(false)}
								onKeyDown={() => setFirstDevice(false)}
								className={`${styles.selectCountryButton}  ${!firstDevice ? styles.active : ''}`}
							>
								{intl.formatMessage({ id: 'no' })}
							</div>
						</FadeIn>
					</Col>
				</Row>

				<FadeIn delay={1200}>
					<Button onClick={() => onComplete({ country, firstDevice })} disabled={!country}>
						{intl.formatMessage({ id: 'continue' })}
					</Button>
				</FadeIn>
			</Grid>
		</div>
	);
};

export default SelectCountry;
