import React, { useState } from 'react';
import styles from './styles.module.scss';
import IconPress from '@assets/icon-press.svg';
import IconSwipe from '@assets/icon-swipe.svg';
import IconDownArrow from '@assets/icon-down-arrow.svg';
import { useIntl } from 'gatsby-plugin-intl';
import Steps from '@components/Steps';
import { useSelector } from 'react-redux';
import FadeIn from '@components/FadeIn';

const TourModal = ({ onClose }) => {
	const intl = useIntl();
	const [step, setStep] = useState(1);
	const user = useSelector(state => state.user);
	const country = user?.country;

	const progressStep = () => {
		if (step >= 3 || (step >= 2 && !country)) onClose();
		setStep(step + 1);
	};

	return (
		<div className={`${styles.tourModal} ${step === 2 ? styles.tourModalPadding: ''}`} onClick={progressStep}>
			<div className={styles.tourModalClose} onClick={onClose}>
				<FadeIn delay={300}>{intl.formatMessage({ id: 'tour.skip' })}</FadeIn>
			</div>

			<div className={styles.tourModalWrapper}>
				<FadeIn delay={600}>
					{step === 1 && (
						<div>
							<IconPress />
							<p>{intl.formatMessage({ id: 'tour.step1' })}</p>
						</div>
					)}
					{step === 2 && (
						<div className={styles.tourModalTap}>
							<div>
								<IconPress />
								<IconPress />
							</div>
							<p>{intl.formatMessage({ id: 'tour.step2' })}</p>
						</div>
					)}
					{step === 3 && (
						<div className={styles.tourModalArrows}>
							<p>{intl.formatMessage({ id: 'tour.step3' })}</p>
							<div>
								<IconDownArrow />
								<IconDownArrow />
							</div>
						</div>
					)}
				</FadeIn>
			</div>

			<FadeIn delay={900}>
				<div className={styles.stepsWrapper}>
					<Steps current={step} hideNumbers>
						<Steps.Step name={1} complete={false} />
						<Steps.Step name={2} complete={false} />
						{country && <Steps.Step name={3} complete={false} />}
					</Steps>
				</div>
			</FadeIn>
		</div>
	);
};

export default TourModal;
