import React from 'react';
import styles from './styles.module.scss';
import Button from '@components/Button';
import IconPhone3 from '@assets/icon-phone3.svg';
import IconArrow from '@assets/icon-left-arrow.svg';
import { useIntl } from 'gatsby-plugin-intl';
import supportedCountries from '@data/supportedCountries.json';
import { useSelector } from 'react-redux';
import FadeIn from '@components/FadeIn';

const CallModal = ({ onClose }) => {
	const intl = useIntl();
	const user = useSelector(state => state.user);
	const country = user?.country;
	const number = supportedCountries.find(supportedCountry => supportedCountry.code === country.toUpperCase())?.phone;

	return (
		<div className={styles.callModal}>
			<div className={styles.callModalBg} />
			<div className={styles.callModalWrapper}>
				<FadeIn>
					<div className={styles.callModalClose} onClick={onClose}>
						<IconArrow /> <p>{intl.formatMessage({ id: 'callModal.back' })}</p>
					</div>
				</FadeIn>
				<FadeIn delay={300}>
					<h1>{intl.formatMessage({ id: 'steps.videos.callToRegister' })}</h1>
				</FadeIn>
				<FadeIn delay={600}>
					<p>{intl.formatMessage({ id: 'callModal.text' })}</p>
				</FadeIn>
				<FadeIn delay={900}>
					<Button
						Icon={IconPhone3}
						onClick={() => {
							if (typeof window !== 'undefined') {
								window.location.href = `tel:${number}`;
							}
						}}
					>
						{number}
					</Button>
				</FadeIn>
				<FadeIn delay={1200}>
					<b>{intl.formatMessage({ id: 'callModal.tollFree' })}</b>
				</FadeIn>
				<FadeIn delay={1400}>
					<div className={styles.callModalText}>
						<p>{intl.formatMessage({ id: 'callModal.weekdays' })}</p>
						<p>{intl.formatMessage({ id: 'callModal.weekends' })}</p>
					</div>
				</FadeIn>
			</div>
		</div>
	);
};

export default CallModal;
