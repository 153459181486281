import styles from './styles.module.scss'
import React, { useEffect, useState } from 'react'

const FadeIn = ({ children, delay = 0 }) => {
	const [showLoading, setShowLoading] = useState(false)
	useEffect(() => {
		let timer1 = setTimeout(() => setShowLoading(true), delay)
		return () => clearTimeout(timer1)
	}, [])
	return (
		<div className={`${styles.fadeIn} ${showLoading ? styles.fadeInVisible : ''}`}>
			{children}
		</div>
	)
}

export default FadeIn
